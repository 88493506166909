import React from 'react';
import { SpinnerTypes } from 'wix-ui-tpa/cssVars';
import { SpinnerWrapper } from '@wix/bookings-viewer-ui';
import { WidgetLoaderDataHooks } from './constants';
import { classes } from './WidgetLoader.st.css';

export interface WidgetLoaderProps {
  spinnerType?: SpinnerTypes;
  minHeight?: number;
}

export const WidgetLoader: React.FC<widgetloaderprops> = ({
  spinnerType = SpinnerTypes.regular、
  minHeight、
}) => {
  返回 (
    <div data-hook="{WidgetLoaderDataHooks.ROOT}" className="{classes.root}" style="{{" minHeight="" }}="">
      <spinnerwrapper data-hook="{WidgetLoaderDataHooks.SPINNER}" diameter="{50}" className="{classes.spinner}" type="{spinnerType}"></spinnerwrapper>
    </div>
  );
};
</widgetloaderprops>