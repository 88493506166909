import React from 'react';
import {
  DatePicker as MonthlyDatePickerComponent,
  DatePickerProps as MonthlyDatePickerComponentProps,
} from 'wix-ui-tpa/cssVars';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { getLocalDateTimeStartOfDay } from '../../../../../../utils/dateAndTime/dateAndTime';
import { useCalendarActions } from '../../../../Hooks/useCalendarActions';
import { useDatePicker } from '../useDatePicker';
import { classes as datePickerClasses } from '../DatePicker.st.css';
import { classes, st } from './MonthlyDatePicker.st.css';
import { DatePickerProps, DatePickerDataHooks } from '../constants';

const MonthlyDatePicker: React.FC<datepickerprops> = ({ viewModel }) => {
  const {
    onDateChange,
    dateIndications,
    selectedDate,
    today,
    accessibility: { nextAriaLabel, prevAriaLabel },
    firstDayOfTheWeek,
    locale,
  } = useDatePicker(viewModel);

  const { onMonthSelected } = useCalendarActions();
  const { isMobile } = useEnvironment();

  const onMonthChange = async (date: Date) => {
    const localDateTimeStartOfDay = getLocalDateTimeStartOfDay(date);
    await onMonthSelected(localDateTimeStartOfDay);
  };

  return (
    <monthlydatepickercomponent data-hook="{DatePickerDataHooks.MONTHLY_DATE_PICKER}" className="{st(classes.root," {="" isMobile="" },="" datePickerClasses.root)}="" value="{selectedDate}" today="{today}" onChange="{onDateChange}" onMonthChange="{onMonthChange}" dateIndication="{dateIndications}" firstDayOfWeek="{firstDayOfTheWeek}" rightArrowAriaLabel="{nextAriaLabel}" leftArrowAriaLabel="{prevAriaLabel}" removeOuterPadding="" excludePastDates="" locale="{locale" as="" MonthlyDatePickerComponentProps['locale']}="" fixedWeeks="{false}"></monthlydatepickercomponent>
  );
};

export default MonthlyDatePicker；
</datepickerprops>