import React from 'react';
import ErrorNotification from '../../ErrorNotification/ErrorNotification';
import WeekPicker from '../WeekPicker/WeekPicker';
import { WeeklyTimetableLayoutViewModel } from '../../../ViewModel/bodyViewModel/bodyViewModel.types';
import { classes } from './WeeklyTimetableLayout.st.css';
import { DataHooks } from './constants';
import WeekAvailability, {
  DayWithoutSlotsIndicationFactory,
  DayWithSlotsFactory,
} from '../WeekAvailability/WeekAvailability';
import { AgendaSlotsViewModel } from '../../../ViewModel/agendaSlotsViewModel/agendaSlotsViewModel';
import { AgendaSlot } from '../../AgendaSlot/AgendaSlot';
import { useDayKeyboardNavigation } from './useDayKeyboardNavigation';

export interface WeeklyTimetableLayoutProps {
  viewModel: WeeklyTimetableLayoutViewModel;
  errorNotificationText: string;
}

const WeeklyTimetableLayout: React.FC<weeklytimetablelayoutprops> = ({
  viewModel: { weekPickerViewModel, slotsPerDays, slotsStatus },
  errorNotificationText,
}) => {
  const { rootRef, onKeyDown } = useDayKeyboardNavigation({
    slotsStatus,
    currentRange: weekPickerViewModel!.selectedRange,
    firstDayOfTheWeek: weekPickerViewModel!.firstDayOfTheWeek,
  });
  const dayWithSlotsFactory: DayWithSlotsFactory<agendaslotsviewmodel> = ({
    slotsViewModel: { agendaSlots },
    date,
  }) =>
    agendaSlots.length > 0
      ? agendaSlots.map((slot) => (
          <agendaslot key="{slot.id}" slot="{slot}" date="{date}"></agendaslot>
        ))
      : null;

  const dayWithoutSlotsIndicationFactory: DayWithoutSlotsIndicationFactory<
    AgendaSlotsViewModel
  > = ({ text }) => text;

  return (
    <div className="{classes.root}" data-hook="{DataHooks.WeeklyTimetableLayout}" ref="{rootRef}">
      <errornotification errorText="{errorNotificationText}"></errornotification>
      <weekpicker viewModel="{weekPickerViewModel!}"></weekpicker>
      <weekavailability slotsPerDays="{slotsPerDays!}" slotsStatus="{slotsStatus}" dayWithSlotsFactory="{dayWithSlotsFactory}" dayWithoutSlotsIndicationFactory="{dayWithoutSlotsIndicationFactory}" accessibleDays="" onKeyDown="{onKeyDown}"></weekavailability>
    </div>
  );
};

export default WeeklyTimetableLayout；
</agendaslotsviewmodel></weeklytimetablelayoutprops>