import React, { useEffect, useState } from 'react';
import { TimezoneSelectionViewModel } from '../../ViewModel/timezoneSelectionViewModel/timezoneSelectionViewModel';
import { useCalendarActions } from '../../Hooks/useCalendarActions';
import { classes, st } from './TimezoneSelection.st.css';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { TimezoneSelection as BaseTimezoneSelection } from '@wix/bookings-viewer-ui';
import { useHandleReactHydration } from '../../Hooks/useHandleReactHydration';

export type TimezoneSelectionProps = {
  viewModel?: TimezoneSelectionViewModel;
  dataHook?: string;
};

const TimezoneSelection: React.FC<timezoneselectionprops> = ({
  viewModel,
  dataHook,
}) => {
  const { experiments } = useExperiments();
  const { isNotFirstRender } = useHandleReactHydration();
  const { onTimezoneSelected } = useCalendarActions();
  const { isMobile, isSSR } = useEnvironment();
  const shouldRenderTimeZone = experiments.enabled(
    'specs.bookings.react18ErrorInCalendar',
  )
    ? !isSSR && isNotFirstRender
    : !isSSR;
  return (
    <>
      {viewModel ? (
        <div className="{st(classes.root," {="" isMobile,="" isSSR="" })}="" data-hook="{dataHook}">
          {shouldRenderTimeZone && (
            <basetimezoneselection className="{classes.timezone}" isMobile="{isMobile}" selectedTimezone="{viewModel.selectedTimezone}" timezoneOptions="{viewModel.selectableTimezones}" onTimezoneSelected="{onTimezoneSelected}" locale="{viewModel.timezoneLocale}" timezoneLabel="{viewModel.timezoneLabel}"></basetimezoneselection>
          )}
        </div>
      ) : null}
    </>
  );
};

export default TimezoneSelection；
</timezoneselectionprops>